<template>
  <div>
    <div>
      <calendar-bar v-model="dateRange" @input="refresh" />
      <b-row>
        <template v-for="status in monitoringStatuses" >
          <b-col cols="4" v-bind:key="status">
            <statistic-card-horizontal :icon="$t(`monitoringStatuses.${status}.icon`)" v-bind:statistic="(stats[status] || {count:0}).count" v-bind:loading="loading.stats || stats[status] == null"
                                       :description="$t(`monitoringStatuses.${status}.desc`)" :statistic-title="$t(`monitoringStatuses.${status}.title`)"
                                       icon-type="fontawesome" :color="$t(`monitoringStatuses.${status}.variant`)"
            />
          </b-col>
        </template>
      </b-row>
      <b-row>
        <b-col cols="12">
          <h5 class="my-1 text-uppercase text-muted">Novos processos</h5>
        </b-col>
        <b-col cols="8">
          <b-card>
            <b-card-title>
              {{ $t('stats.reviews.userTitle') }}
            </b-card-title>
            <suit-reviews-bar-chart v-bind:loading="loading.review" v-bind:users-reviews="reviewStats.userReviews"/>
            <h5 class="text-right">Total de processos revisados: {{reviewStats.totalReviews}}</h5>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-card-title>
              {{ $t('stats.reviews.sectionTitle') }}
            </b-card-title>
            <suit-reviews-section-donut-chart v-bind:loading="loading.review" v-bind:sections-reviews="reviewStats.userReviews"/>
<!--            <b-skeleton-img></b-skeleton-img>-->

          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal'
import { mapActions, mapGetters } from 'vuex'
import SuitReviewsBarChart from '@/components/partial/stats/SuitReviewsBarChart'
import SuitReviewsSectionDonutChart from '@/components/partial/stats/SuitReviewsSectionDonutChart'
import CalendarBar from '@/components/widget/CalendarBar'


const defaultStatsSubObj = () =>({count:0,subsections:[]})

export default {
  components: {
    CalendarBar,
    SuitReviewsSectionDonutChart,
    SuitReviewsBarChart,
    StatisticCardHorizontal
  },
  data: () => ({ dateRange:{}, loading: { stats:true, review:true }, stats: {}, reviewStats:{} }),
  computed:{
    ...mapGetters('suits',['monitoringStatuses']),
  },
  methods: {
    ...mapActions('suits', ['getSuitStats','getSuitReviewStats']),
    refresh(){
      let $this = this;
      this.loading.stats = true;
      this.loading.review = true;
      this.getSuitStats(this.dateRange).then(result=>{
        $this.stats = result.monitoringStats.reduce($this.mergeStatsObject,{})
        $this.monitoringStatuses.forEach(status=>{
          if($this.stats[status] == null){
            $this.mergeStatsObject($this.stats,{ status, count:0, subsections:[] })
          }
        })
      }).finally(()=>{
        this.loading.stats = false;
      });
      this.getSuitReviewStats(this.dateRange).then(result=>{
        $this.reviewStats = result;
      }).finally(()=>{
        this.loading.review = false;
      });
    },
    mergeStatsObject(obj,stat){
      if(obj[stat.status] == null){
        obj[stat.status] = Object.assign(defaultStatsSubObj(),{ ...stat });
        obj[stat.status].log = [stat]
      }else {
        obj[stat.status].count += stat.count;
        obj[stat.status].subsections.concat(stat.subsections);
        obj[stat.status].log.push(stat);
      }
      return obj
    }
  }
}
</script>

<style>

</style>
