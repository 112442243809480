<template>
<div class="mb-2">
  <div class="text-center">
    <div class="d-inline-block px-1" :style="`width: ${Math.floor(100/options.length)}%`" v-for="option in options" v-bind:key="option">
      <b-button block v-bind:variant="selected === option ? 'primary' : 'outline-primary'"  size="sm" @click="onOptionSelected(option)">
        {{$t(`filters.picker.${option}`)}}
      </b-button>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: 'CalendarBar',
  data:()=>({
    options:[
        'today', 'yesterday', 'thisWeek', 'lastWeek','last7Days','thisMonth','lastMonth','last30Days','thisYear'
    ],
    selected: 'thisWeek'
  }),
  props:{
    value:{
      type: String,
      default: null
    },
  },
  created() {
    this.onOptionSelected(this.selected)
  },
  methods:{
    onOptionSelected(option){
      this.selected = option
      this.$emit('input', this.getValue(option))
    },
    getValue(option){
      switch (option){
        case 'today':
          return { from: this.$moment().format('YYYY-MM-DD'), until: this.$moment().format('YYYY-MM-DD') };
        case 'yesterday':
          return { from: this.$moment().subtract(1, 'days').format('YYYY-MM-DD'), until: this.$moment().subtract(1, 'days').format('YYYY-MM-DD') };
        case 'thisWeek':
          return { from: this.$moment().startOf('week').format('YYYY-MM-DD'), until: this.$moment().endOf('week').format('YYYY-MM-DD') };
          case 'lastWeek':
          return { from: this.$moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'), until: this.$moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD') };
        case 'last7Days':
          return { from: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), until: this.$moment().format('YYYY-MM-DD') };
        case 'thisMonth':
          return { from: this.$moment().startOf('month').format('YYYY-MM-DD'), until: this.$moment().endOf('month').format('YYYY-MM-DD') };
        case 'lastMonth':
          return { from: this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), until: this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') };
        case 'last30Days':
          return { from: this.$moment().subtract(30, 'days').format('YYYY-MM-DD'), until: this.$moment().format('YYYY-MM-DD') };
        case 'thisYear':
          return { from: this.$moment().startOf('year').format('YYYY-MM-DD'), until: this.$moment().endOf('year').format('YYYY-MM-DD') };

      }
    }
  }
}
</script>

<style scoped>

</style>
