<template>
<div>
  <div v-if="loading !== true">
    <vue-apex-charts
        ref="reviewsBarChart"
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
    />
  </div>
  <div v-else style="max-height: 300px;overflow: hidden">
    <b-skeleton-img></b-skeleton-img>
  </div>
</div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {mapGetters} from "vuex";

export default {
  name: 'SuitReviewsBarChart',
  components: {
    VueApexCharts,
  },
  props:{
    loading:{
      type: Boolean,
      default: false
    },
    usersReviews:{
      type: Array,
      default: () => []
    }
  },
  watch: {
    usersReviews() {
      if(this.$refs['reviewsBarChart'] != null)
        this.$refs['reviewsBarChart'].refresh();
    }
  },
  computed:{
    ...mapGetters('data',['getUserFullName']),
    chartOptions() {
      let $this = this;
      console.log("chartOptions",{users:$this.usersReviews.filter(r=>r.userId != null)})
      return {
        chart: {
          id: 'users-reviews-chart'
        },
        xaxis: {
          categories: $this.usersReviews.filter(r=>r.userId != null).map((review)=> $this.getUserFullName(review.userId))
        }
      }
    },
    series(){
      let $this = this;
      return [{
        name:"",
        // data:[1,2,3,4,5,6,7,8]
        data:$this.usersReviews.filter(r=>r.userId != null).map((review)=> review.totalReviews)
      }]

    },
  }
}
</script>

<style scoped>

</style>
