<template>
  <div>
    <div v-if="loading !== true || sectionsReview.length <= 0">
      <div class="users text-center">
        <div class="d-inline-block" v-for="(r,index) in sectionsReviews" v-bind:key="r.userId" @click="onUserSelected(index)">
          <b-avatar
              v-if="r.userId != null"
              class="selectable mx-1"
              size="40"
              variant="light-primary"
              :src="getUserAvatar(r.userId)"
              v-b-tooltip
              :title="getUserFullName(r.userId)"

          />
          <b-button v-else
                    v-bind:variant=" selected === index ? 'primary' : `outline-primary`" size="sm" pill>Todos</b-button>
        </div>

      </div>
      <vue-apex-charts
          ref="reviewsDonutChart"
          type="donut"
          height="350"
          :options="chartOptions"
          :series="series"
      />
    </div>
    <div v-else style="max-height: 300px;overflow: hidden">
      <b-skeleton-img></b-skeleton-img>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'SuitReviewsSectionDonutChart',
  components: {
    VueApexCharts,
  },
  data:()=>({
    selected:0,
  }),
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    sectionsReviews: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    usersReviews() {
      if (this.$refs['reviewsDonutChart'] != null) {
        this.$refs['reviewsDonutChart'].refresh()
      }
    },
    selected() {
      if (this.$refs['reviewsDonutChart'] != null) {
        this.$refs['reviewsDonutChart'].refresh()
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getUserFullName', 'getUserAvatar']),
    selectedSectionsReview() {
      let $this = this
      if($this.sectionsReviews == null || $this.sectionsReviews.length === 0)
        return {}
      return $this.sectionsReviews[this.selected] || [];
    },
    sectionsReview() {
      let $this = this
      if($this.sectionsReviews == null || $this.sectionsReviews.length === 0)
        return []
      return $this.selectedSectionsReview.sections;
    },
    chartOptions() {
      let $this = this
      let total = {
        show: true,
        fontSize: '1.5rem',
        label: this.$t('stats.reviews.sectionSubtitle'),
        formatter() {
          return $this.selectedSectionsReview.totalReviews
        },
      }
      return {
        chart: {
          id: 'vuechart-example'
        },
        legend: {
          show: false,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  /* formatter(val) {
                     // eslint-disable-next-line radix
                     return `${parseInt(val)}`
                   },*/
                },
                total,
              },
            },
          },
        },
        labels: $this.sectionsReview.map((section) => section.section)
      }
    },
    series() {
      let $this = this
      return $this.sectionsReview.map((section) => section.total)

      return [{
        name: '',
        // data:[1,2,3,4,5,6,7,8]
        data: $this.sectionsReviews.map((review) => review.totalReviews)
      }]

    },
  },
  methods:{
    onUserSelected(index) {
      this.selected = index
    }
  }
}
</script>

<style scoped>

</style>
